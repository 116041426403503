(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ol/style/Style"), require("ol/style/Fill"), require("ol/style/Stroke"), require("ol/Observable"), require("ol/proj"), require("ol/style/Icon"), require("ol/tilegrid/TileGrid"), require("ol/Map"), require("ol/layer/Vector"), require("ol/layer/VectorTile"), require("ol/source/TileJSON"), require("ol/source/Vector"), require("ol/source/VectorTile"), require("ol/style/Text"), require("ol/style/Circle"), require("ol/tilegrid"), require("ol/View"), require("ol/format/GeoJSON"), require("ol/format/MVT"), require("ol/layer/Tile"));
	else if(typeof define === 'function' && define.amd)
		define(["ol/style/Style", "ol/style/Fill", "ol/style/Stroke", "ol/Observable", "ol/proj", "ol/style/Icon", "ol/tilegrid/TileGrid", "ol/Map", "ol/layer/Vector", "ol/layer/VectorTile", "ol/source/TileJSON", "ol/source/Vector", "ol/source/VectorTile", "ol/style/Text", "ol/style/Circle", "ol/tilegrid", "ol/View", "ol/format/GeoJSON", "ol/format/MVT", "ol/layer/Tile"], factory);
	else if(typeof exports === 'object')
		exports["olms"] = factory(require("ol/style/Style"), require("ol/style/Fill"), require("ol/style/Stroke"), require("ol/Observable"), require("ol/proj"), require("ol/style/Icon"), require("ol/tilegrid/TileGrid"), require("ol/Map"), require("ol/layer/Vector"), require("ol/layer/VectorTile"), require("ol/source/TileJSON"), require("ol/source/Vector"), require("ol/source/VectorTile"), require("ol/style/Text"), require("ol/style/Circle"), require("ol/tilegrid"), require("ol/View"), require("ol/format/GeoJSON"), require("ol/format/MVT"), require("ol/layer/Tile"));
	else
		root["olms"] = factory(root["ol"]["style"]["Style"], root["ol"]["style"]["Fill"], root["ol"]["style"]["Stroke"], root["ol"]["Observable"], root["ol"]["proj"], root["ol"]["style"]["Icon"], root["ol"]["tilegrid"]["TileGrid"], root["ol"]["Map"], root["ol"]["layer"]["Vector"], root["ol"]["layer"]["VectorTile"], root["ol"]["source"]["TileJSON"], root["ol"]["source"]["Vector"], root["ol"]["source"]["VectorTile"], root["ol"]["style"]["Text"], root["ol"]["style"]["Circle"], root["ol"]["tilegrid"], root["ol"]["View"], root["ol"]["format"]["GeoJSON"], root["ol"]["format"]["MVT"], root["ol"]["layer"]["Tile"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__23__) {
return 